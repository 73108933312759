import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/index.vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import EmptyLayout from "@/layout/emptyPage.vue";
import { ApplicationClaimType } from "./api-client";
import { fas, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

/* 
  Claim types:
  ManageClaims
  ManageUsers
  ViewCustomer
  ManageCustomer
  ManageOrders
  ViewOrders
*/

const router =  new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.path != from.path) {
        return { x: 0, y: 0 };
      }
      
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
      meta: {
        title: "Login",
        hidden: true,
      },
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"),
      meta: {
        title: "Forgot Password",
        hidden: true,
      },
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: () => import(/* webpackChunkName: "register" */ "@/views/auth/Register.vue"),
    //   meta: {
    //     title: "register",
    //     hidden: true,
    //   },
    // },
    // {
    //   path: "/confirm-email",
    //   name: "EmailVerification",
    //   component: () => import(/* webpackChunkName: "email-verification" */ "@/views/auth/EmailVerification.vue"),
    //   props: true,
    //   meta: {
    //     title: "Email Verification",
    //     hidden: true,
    //   }
    // },
    {
      path: "/404",
      name: "Error",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
      meta: {
        title: "Page Not Found",
        hidden: true,
      },
    },
    {
      path: "/",
      component: Layout,
      redirect: "/dashboard",
      name: "Dashboard",
      meta: {
        title: "Orders Dashboard"
      },
      children: [
        {
          path: "dashboard",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
            ),
          meta: {
            title: "Orders Dashboard",
            breadcrumb: false,
            icon: "dashboard",
          },
        },
      ],
    },
    {
      path: "/search",
      component: Layout,
      meta: {
        title: "Search",
        claim: { claimType: "ViewOrders" },
      },
      children: [
        {
          path: "",
          name: "Search",
          component: () => import(/* webpackChunkName: "search" */ "@/views/search/list.vue"),
          meta: {
            title: "Search",
            claim: { claimType: "ViewOrders" },
            icon: "search",
            breadcrumb: false,
          },
        },
        {
          path: "/order/:id",
          name: "OrderDetail",
          component: () => import(/* webpackChunkName: "order-detail" */ "@/views/orders/detail.vue"),
          props: true,
          meta: {
            title: "Order",
            claim: { claimType: "ViewOrders" },
            hidden: true,
            breadcrumb: true,
          },
        },
        {
          path: "/customer/:id",
          name: "CustomerDetail",
          component: () => import(/* webpackChunkName: "customer-detail" */ "@/views/customers/detail.vue"),
          props: true,
          meta: {
            title: "Customer",
            claim: { claimType: "ViewCustomer" },
            hidden: true,
            breadcrumb: true,
          },
        },
      ]
    },
    {
      // TODO revise claim type
      path: "/payments-due",
      component: Layout,
      meta: {
        title: "Payments Due",
        claim: { claimType: "ManageUsers" },
      },
      children: [
        {
          path: "",
          name: "PaymentsDue",
          component: () => import(/* webpackChunkName: "payments-due" */ "@/views/payments-due/list.vue"),
          meta: {
            title: "Payments Due",
            claim: { claimType: "ManageUsers" },
            icon: "payments",
            breadcrumb: false,
          },
        }
      ]
    },
    {
      path: "/product-management",
      component: Layout,
      meta: {
        title: "Products",
        claim: {claimType: "ManageUsers" },
        // TODO get correct claim
      },
      children: [
        {
          path: "",
          name: "Products",
          component: () => import(/* webpackChunkName: "products" */ "@/views/products/list.vue"),
          meta: {
            title: "Product Management",
            icon: "product-management",
            breadcrumb: false,
            // TODO get correct claim
            claim: {claimType: "ManageUsers" },
          },
        },
        {
          path: "product/:id",
          name: "ProductDetail",
          component: () => import(/* webpackChunkName: "product-detail" */ "@/views/products/detail.vue"),
          props: true,
          meta: {
            title: "Product Detail",
            // TODO get correct claim
            claim: {claimType: "ManageUsers" },
            hidden: true
          },
        },
        {
          path: "product",
          name: "NewProduct",
          component: () => import(/* webpackChunkName: "new-product" */ "@/views/products/detail.vue"),
          meta: {
            title: "New Product",
            // TODO get correct claim
            claim: {claimType: "ManageUsers" },
            // breadcrumb: false,
            hidden: true
            // icon: "plus",
            // claim: {claimType: "ManageUsers" }
          },
        }
      ]
    },
    {
      path: "/user-management",
      component: Layout,
      meta: {
        title: "Users",
        claim: {claimType: "ManageUsers" },
      },
      children: [
        {
          path: "",
          name: "Users",
          component: () => import(/* webpackChunkName: "users" */ "@/views/users/list.vue"),
          meta: {
            title: "User Management",
            icon: "user",
            breadcrumb: false,
            claim: {claimType: "ManageUsers" },
          },
        },
        {
          path: "user/:id",
          name: "UserDetail",
          component: () => import(/* webpackChunkName: "user-detail" */ "@/views/users/detail.vue"),
          props: true,
          meta: {
            title: "User Detail",
            claim: {claimType: "ManageUsers" },
            hidden: true
          },
        },
        {
          path: "user",
          name: "NewUser",
          component: () => import(/* webpackChunkName: "new-user" */ "@/views/users/detail.vue"),
          meta: {
            title: "New User",
            claim: {claimType: "ManageUsers" },
            // breadcrumb: false,
            hidden: true
            // icon: "plus",
            // claim: {claimType: "ManageUsers" }
          },
        }
      ]
    },
    {
      path: "/user-activity",
      component: Layout,
      meta: {
        title: "User Activity",
        // TODO get correct claim
        claim: {claimType: "ManageUsers" }
      },
      children: [
        {
          path: "",
          name: "UserActivity",
          component: () => import(/* webpackChunkName: "user-activity" */ "@/views/user-activity/list.vue"),
          meta: {
            title: "User Activity",
            claim: { claimType: "ManageUsers" },
            icon: "user-activity",
            breadcrumb: false,
          },
        },
      ]
    },
    {
      // TODO revise claim type
      path: "/importers",
      component: Layout,
      meta: {
        title: "Importers",
        claim: { claimType: "ManageUsers" },
      },
      children: [
        {
          path: "",
          name: "Importers",
          component: () => import(/* webpackChunkName: "importers" */ "@/views/importers/index.vue"),
          meta: {
            title: "Importers",
            claim: { claimType: "ManageUsers" },
            icon: "importers",
            breadcrumb: false,
          },
        }
      ]
    },
    {
      // TODO revise claim type
      path: "/toolbox",
      component: Layout,
      meta: {
        title: "Toolbox",
        claim: { claimType: "ManageUsers" },
      },
      children: [
        {
          path: "",
          name: "Toolbox",
          component: () => import(/* webpackChunkName: "toolbox" */ "@/views/toolbox/toolbox.vue"),
          meta: {
            title: "Toolbox",
            claim: { claimType: "ViewOrders" },
            icon: "tools",
            breadcrumb: false,
          },
        }
      ]
    },
    {
      path: "*",
      redirect: "/404",
      meta: { hidden: true },
    },
  ]
});

// router.beforeEach((to, from) => {
//   // Save the 'from' route name to meta
//   // Used in conjuntion with the goBack method in utilitis/index.ts
//   // Search this reference for all related code & examples: queryCoolness
//   if (from.name && to.meta) {
//     to.meta.fromRoute = true;
//   }
// });

export default router;



